import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Footer() {
  return (
    <footer className="bg-primary">
      <nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm">

        <p className="text-secondary">
          Created by{` `}
          <a
          className="font-bold no-underline text-secondary"
          href="https://netme.com.br"
          >
          NetMe
        </a>
      </p>

    <div>
      <a
        href="https://www.facebook.com/infranology"
        rel="noopener noreferrer"
        target="_blank"
        alt="chamar por whatsapp">
        <svg
          className="text-secondary w-10 h-10"
          viewBox="0 0 512 512">
          <path fill="currentColor" d="M504 256a248 248 0 10-287 245V328h-63v-72h63v-55c0-62 37-96 94-96 27 0 55 5 55 5v61h-31c-31 0-40 19-40 38v47h69l-11 72h-58v173a248 248 0 00209-245z"/>
        </svg>
      </a>
    </div>

    <div>
      <a
        href="https://twitter.com/infranology"
        rel="noopener noreferrer"
        target="_blank"
        alt="chamar por whatsapp">
        <svg
          className="text-secondary w-10 h-10"
          viewBox="0 0 512 512">
          <path fill="currentColor" d="M459 152l1 13c0 139-106 299-299 299-59 0-115-17-161-47a217 217 0 00156-44c-47-1-85-31-98-72l19 1c10 0 19-1 28-3-48-10-84-52-84-103v-2c14 8 30 13 47 14A105 105 0 0136 67c51 64 129 106 216 110-2-8-2-16-2-24a105 105 0 01181-72c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z"/>
        </svg>
      </a>
    </div>

    <div>
      <a
        href="https://github.com/infranology"
        rel="noopener noreferrer"
        target="_blank"
        alt="chamar por whatsapp">
        <svg
          viewBox="0 0 480 512"
          className="text-secondary w-10 h-10"
          >
          <path fill="currentColor" d="M186 329c0 21-11 55-37 55s-36-34-36-55 11-55 36-55 37 34 37 55zm294-51c0 32-3 66-17 95-38 77-143 75-217 75-76 0-186 3-226-75-14-29-20-63-20-95 0-42 14-81 42-113-6-16-8-33-8-49 0-22 5-32 14-52 46 0 75 9 109 36a383 383 0 01169-1c34-26 63-35 108-35 10 20 15 30 15 52 0 16-3 33-8 48 28 32 39 72 39 114zm-64 51c0-44-27-83-74-83-19 0-37 3-56 6a294 294 0 01-90 0c-19-3-37-6-56-6-47 0-73 39-73 83 0 87 80 101 150 101h48c70 0 151-13 151-101zm-83-55c-26 0-37 34-37 55s11 55 37 55 37-34 37-55-11-55-37-55z"/>
        </svg>
      </a>
    </div>

    <div>
      <a
        href="https://www.linkedin.com/company/infranology"
        rel="noopener noreferrer"
        target="_blank"
        alt="linkedin">
        <svg
          className="text-secondary w-10 h-10"
          viewBox="0 0 448 512">
          <path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
        </svg>
      </a>
    </div>

    <div>
      <a
        href="https://api.whatsapp.com/send?phone=5511993561627"
        rel="noopener noreferrer"
        target="_blank"
        alt="chamar por whatsapp">
        <svg
          className="text-secondary w-10 h-10"
          viewBox="0 0 448 512">
          <path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
        </svg>
      </a>
    </div>


  </nav>
  </footer>
  )
};

export default Footer;
