import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-white md:mx-6">
      <div className="flex flex-wrap items-center justify-between max-w-6xl mx-auto p-6 w-full text-2xl">
        <Link className="flex items-center no-underline" to="/">
          <svg
            className="fill-current h-16 mr-2 w-16"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1400.08 1787.559"
            height="77"
            width="60">
            <g fillRule="evenodd"><path d="M0 .865V723.7l368.231 212.598V213.463zM0 723.7v850.396l368.231 212.597V936.298z"fill="#54d3ce"/><path d="M662.816 426.062L368.23 596.142v340.156l294.586-170.08zm0 340.156L368.23 936.298v850.395l294.586-170.078v-170.08h0z" fill="#1b1c6b" stroke="#1b1c6b"/><path d="M662.816 426.063l736.461 765.354-294.585 170.08-736.461-765.355z" fill="#1b1c6b" stroke="#1b1c6b"/><path d="M1104.692 170.945L1399.277.866v1190.55l-294.585 170.08z" fill="#1b1c6b" stroke="#1b1c6b"/></g>
          </svg>
          <span className="font-bold text-3xl tracking-tight text-primary">
            {site.siteMetadata.title}
          </span>
        </Link>

        <button
          className="block border border-white flex items-center px-3 py-2 rounded text-primary focus:outline-none"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-8 md:h-10 w-8 md:w-10"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full`}
        >
          {[
            {
              route: `/`,
              title: `Home`
            },
            {
              route: `/about`,
              title: `Sobre`
            },
            {
              route: `/blog`,
              title: `Blog`
            },
            {
              route: `/contact`,
              title: `Contato`
            }
          ].map(link => (
            <Link
              className="block mt-4 no-underline text-primary md:text-right"
              key={link.title}
              to={link.route}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
